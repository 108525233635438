import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from '../../helpers/globalContext';
import config from '../../../config';

export const AccountIcon = React.memo(() => {
  const { user, mainRoute, lang } = useGlobal();
  let icoName = '';

  if (user.firstName && user.firstName.hy) {
    icoName = user.firstName.hy;
  } else if (
    !(
      user && // 👈 null and undefined check
      Object.keys(user).length === 0 &&
      Object.getPrototypeOf(user) === Object.prototype
    )
  ) {
    icoName = config.translate.account[lang];
  } else {
    icoName = config.translate.logIn[lang];
  }

  return (
    <Link className='account-icon' to={`${mainRoute}account/`}>
      <img src='/assets/Account_icon.svg' alt='account' />
      <p className='account-icon-text'>{icoName}</p>
      {user && user._id && <span className='account-icon-indicator'></span>}
    </Link>
  );
});
