import axios from 'axios';
import { cookies } from './cookies';
import config from '../../config';
import MD5 from 'md5';
// const LocalData =
//   localStorage.getItem("data") &&
//   localStorage.getItem("data") !== "null" &&
//   localStorage.getItem("data") !== "undefined" &&
//   localStorage.getItem("data") !== ""
//     ? JSON.parse(localStorage.getItem("data"))
//     : "";
const API = axios.create({
  baseURL: `${config.api.API_URL}/${config.api.API_Version}/`,
});
const TELCELL_API = axios.create({
  baseURL: `https://telcellmoney.am/invoices`,
});
const IDRAM_API = axios.create({
  baseURL: `${config.api.IDRAM_API_URL}`,
});

export const settings = {
  onUploadProgress: function (progressEvent) {
    // eslint-disable-next-line
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
  },
  headers: {
    'client-keyFile': config.authentication.key,
    'client-token': cookies.get(config.authentication.cookieToken),
  },
};

export function get(
  route,
  query,
  sort,
  uploadType,
  tags,
  sale,
  NoNotAvailable,
  top
) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  let completeRoute = `${route}${query ? `?q=${JSON.stringify(query)}` : ''}${sort ? `&sort=${JSON.stringify(sort)}` : ''
    }${uploadType ? `&uploadType=${JSON.stringify(uploadType)}` : ''}${tags ? `&tags=${JSON.stringify(tags)}` : ''
    }${sale ? `&sale=${JSON.stringify(sale)}` : ''}${NoNotAvailable ? `&NoNotAvailable=${JSON.stringify(NoNotAvailable)}` : ''
    }${top ? `&top=${JSON.stringify(top)}` : ''}`;
  return new Promise((resolve, reject) => {
    if (route && completeRoute && completeRoute.length !== 0) {
      // console.log(LocalData[route]);
      // if (
      //   (LocalData[route] && query && sort) ||
      //   LocalData[route]
      //   // Object.keys(query).length !== 0 &&
      //   // query.constructor === Object
      // ) {
      //   console.log(
      //     "%c Get information from LocalStorage!",
      //     "background: #222; color: #bada55"
      //   );
      //   if (query) {
      //     let result = LocalData[route].find((element, index, array) => {
      //       // while (start <= Math.sqrt(element)) {
      //       //   if (element % start++ < 1) {
      //       //     return false;
      //       //   }
      //       // }
      //       if (element[Object.keys(query)]) return element;
      //     });
      //     resolve(result);
      //   } else {
      //     resolve(LocalData[route]);
      //   }
      // } else {
      //   console.log(
      //     "%c Get information from Server!",
      //     "background: #222; color: #f2c900"
      //   );
      API.get(completeRoute, settings)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
      // }
    }
  });
}
export function getById(route, id) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return new Promise((resolve, reject) => {
    API.get(`${route}/${id}`, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function post(route, data) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return new Promise((resolve, reject) => {
    API.post(route, data, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function update(route, id, data) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return new Promise((resolve, reject) => {
    API.put(`${route}/${id}`, data, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export function remove(route, id) {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return new Promise((resolve, reject) => {
    API.delete(`${route}/${id}`, settings)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
export const upload = (id, files, lang, destiny) => {
  settings.headers['client-token'] = cookies.get(
    config.authentication.cookieToken
  );
  settings.headers['client-keyFile'] = config.authentication.key;
  return Promise.all(
    files &&
    files.map((file, index) => {
      const formData = new FormData();
      id && formData.append('obj_id', id);
      file && formData.append('file', file);
      lang && formData.append('lang', lang);
      destiny && formData.append('destiny', destiny);
      return new Promise((resolve, reject) => {
        API.post(config.api.uploads, formData, settings)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    })
  );
};
export const TelcellPost = (Total, ProductDescription, ID, lang) => {
  const shop_key =
    'DlulzC7CxFNQl9uJxo4hRvxeDzmwysivryJ8b09aWcsIq7IOk1TFWibePHSSav3tNQAJhmGcIIM3llxL2FaGsYt01f4aN44aknUBybNO8QlsCOVQMKvKV1bnSVKJvap4'; //! This is a static variable
  const shop_id = 'infoicefishsushi@gmail.com'; //! This is a static variable
  const currency = '֏'; //! This is a static variable
  const product = btoa(ProductDescription); //? encode product to base64
  const issuer_id = btoa(ID); //?  encode product to base64
  const valid_days = '1'; //! This is a static variable
  const action = 'PostInvoice'; //! This is a static variable

  // set MD5 hash
  function getTelcellSecurityCode(
    shop_key,
    issuer,
    currency,
    price,
    product,
    issuer_id,
    valid_days
  ) {
    return MD5(
      shop_key + issuer + currency + price + product + issuer_id + valid_days
    );
  }

  const formData = new FormData();
  // static properties
  formData.append('action', action);
  formData.append('currency', currency);
  formData.append('valid_days', valid_days);
  formData.append('issuer', shop_id);
  // dynamic pperties
  formData.append('price', Total);
  formData.append('product', product);
  formData.append('issuer_id', issuer_id);
  formData.append('lang', lang);
  formData.append(
    'security_code',
    getTelcellSecurityCode(
      shop_key,
      shop_id,
      currency,
      Total,
      product,
      issuer_id,
      valid_days
    )
  );

  return new Promise((resolve, reject) => {
    TELCELL_API.post('/', formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const IdramPost = (Total, ProductDescription, ID, lang) => {
  const shop_key =
    '6RiodJcsOescO3XA3Gp73ZFLRS1B3QszoISAqq3Gz1NTv9ZVc8HSmygIu4Js0OFnesWwZSdyLdUYz5iuZRmGn6NVtAU7joiA3IKPOkKJ2V6uP1pjd61DEgH9ByfG864i';
  const shop_id = 'icefisharmenia@gmail.com';
  const currency = '֏';
  const product = btoa(ProductDescription);
  const issuer_id = btoa(ID);
  const valid_days = '1';
  const action = 'PostInvoice';

  // set MD5 hash
  function getTelcellSecurityCode(
    shop_key,
    issuer,
    currency,
    price,
    product,
    issuer_id,
    valid_days
  ) {
    return MD5(
      shop_key + issuer + currency + price + product + issuer_id + valid_days
    );
  }

  const formData = new FormData();
  // static properties
  formData.append('action', action);
  formData.append('currency', currency);
  formData.append('valid_days', valid_days);
  formData.append('issuer', shop_id);
  // dynamic properties
  formData.append('price', Total);
  formData.append('product', product);
  formData.append('issuer_id', issuer_id);
  formData.append('lang', lang);
  formData.append(
    'security_code',
    getTelcellSecurityCode(
      shop_key,
      shop_id,
      currency,
      Total,
      product,
      issuer_id,
      valid_days
    )
  );
  return new Promise((resolve, reject) => {
    TELCELL_API.post('/', formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default API;
