export function RealTimePrice(product, user) {
  if (product) {
    if (user?.client_group_id) {
      if (user.client_group_id === '1') {
        return +product.price?.group1 ? +product.price?.group1 : +product.price?.basic;
      }
      if (user.client_group_id === '2') {
        return +product.price?.group2 ? +product.price?.group2 : +product.price?.basic;
      }
      if (user.client_group_id === '3') {
        return +product.price?.group3 ? +product.price?.group3 : +product.price?.basic;
      }
      return 0
    }
    if (product.price?.sale && Number(product.price?.sale) > 0) {
      return +product.price.sale;
    }
    return +product.price?.basic;
  };
  return 0;
}
