import React, { useState, useRef, useEffect, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';
import Loading from '../helpers/loading';

export const LazyLoadComponent = ({ component, state, load }) => {
  const [isComponentShown, setComponentShown] = useState(false);
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  };
  const { ref, inView, entry } = useInView(options);

  useEffect(() => {
    if (state) {
      setTimeout(() => {
        setComponentShown(true);
      }, 0);
    } else if (inView) setComponentShown(true);
    else if (load) setComponentShown(true);
  }, [inView, load]);

  return (
    <Suspense fallback={<></>}>
      <div ref={ref}>{isComponentShown ? <>{component}</> : null}</div>
    </Suspense>
  );
};
