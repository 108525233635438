import React from 'react';
import { useModal } from '../../helpers/modal_provider';

export const SearchIcon = React.memo(({ className }) => {
  const { openModal } = useModal();

  return (
    <button
      onClick={() => {
        openModal('search');
      }}
    >
      <img src='/assets/Search_icon.svg' alt='search' />
    </button>
  );
});
